<template>
  <v-row>
    <v-col cols="12">
      <h1>{{ title }}</h1>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["title"],
}
</script>
